import styled from "styled-components"
import React from "react"
import branding from "../branding/branding"
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';


const ScrollBar = styled(Slider)`
    .rc-slider-track {
        background-color: transparent;
        height: 0;
    }

    .rc-slider-rail, .rc-slider-step{
        height: 3px;
        background-color: #D9D9D9;
    }

    .rc-slider-handle, .rc-slider-handle:hover, .rc-slider-handle:active, .rc-slider-handle:focus {
        background-color: ${branding.customScrollbarBgColor ?? "#FFF"};
        border-color: ${branding.customScrollbarBorderColor ?? "#000"};
        width: ${branding.customScrollbarWidth ?? "12px"};
        height: ${branding.customScrollbarHeight ?? "12px"};
        outline: none;
        box-shadow: none;
    }

    flex: 1;
`
const ScrollBarVertical = styled(Slider)`
    height: 95%;

    .rc-slider-track {
        background-color: transparent;
        width: 0;
    }

    .rc-slider-rail, .rc-slider-step{
        width: 3px !important;
        background-color: #f2f2f5;
    }

    .rc-slider-handle, .rc-slider-handle:hover, .rc-slider-handle:active, .rc-slider-handle:focus {
        background-color: ${branding.customScrollbarBgColor ?? "#FFF"};
        border-color: ${branding.customScrollbarBorderColor ?? "#000"};
        width: ${branding.customScrollbarWidth ?? "12px"};
        height: ${branding.customScrollbarHeight ?? "12px"};
        outline: none;
        box-shadow: none;
    }
`

const PreScrollPart = styled.div`
    height: 3px;
    width: 20px;
    margin-top: 2px;
    background-color: #D9D9D9;
`

const PreScrollPartVertical = styled.div`
    height: 20px;
    //width: 3px;
    margin-left: 2px;
    background-color: #f2f2f5;
`

const ScrollBarRoot = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;

    .rc-slider{
        height: 3px;
    }
`

const VerticalBarRoot = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    height: 80%;

    .rc-slider-vertical{
        //width: 3px;
    }
`

const VerticalBarDiv = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

interface ScrollBarComponentProps {
    marginLeft?: string
    marginRight?: string
    maxScrollRange: number
    value: number
    onScroll: (position: number) => void
    isVertical?: boolean
}

const ScrollBarComponent: React.FunctionComponent<ScrollBarComponentProps> = (props) => {
    const vertical = props.isVertical ? props.isVertical : false;
    if (props.maxScrollRange && props.maxScrollRange > 0) {
        if (!vertical) {
            return (
                <ScrollBarRoot>
                    <PreScrollPart style={{ marginLeft: props.marginLeft }}></PreScrollPart>
                    <ScrollBar value={props.value} style={{ marginRight: props.marginRight }} min={0} max={props.maxScrollRange} onChange={props.onScroll}></ScrollBar>
                </ScrollBarRoot>
            )
        } else {
            return (
                <VerticalBarRoot className="verticalBarRoot">
                    <VerticalBarDiv>
                        <PreScrollPartVertical />
                        <ScrollBarVertical value={props.value} min={0} max={props.maxScrollRange} onChange={props.onScroll} vertical reverse></ScrollBarVertical>
                    </VerticalBarDiv>
                </VerticalBarRoot >
            )
        }
    } else {
        return <></>
    }

}

export default ScrollBarComponent
