import React from "react"
import styled from "styled-components"
import { useLanguageState } from "../globalStates/LanguageState"
import branding from "../branding/branding"


const LanguageTogglerRoot = styled.div<{ color?: string, fontSize?: string, margin?: string }>`
    display: inline-block;
    font-size: ${props => props.fontSize ?? "1.4rem"};
    text-transform: uppercase;
    margin: ${props => props.margin ?? "0 1rem"};
    color: ${props => props.color ?? "#fff"};
    cursor: pointer;
    font-family: ${branding.font1};
    user-select: none;
`

interface LanguageTogglerProps {
    color?: string
    fontSize?: string
    margin?: string
    fullToggler?: boolean
}

const LanguageToggler: React.FC<LanguageTogglerProps> = (props) => {
    const languageState = useLanguageState()
    const availableLanguages = languageState.getAvailableLanguages()
    const selectedLanguage = languageState.getLanguage()



    const toggleLanguage = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        let selectedLanguageIndex = availableLanguages.findIndex(l => l === selectedLanguage)

        if (availableLanguages[selectedLanguageIndex + 1]) {
            languageState.setLanguage(availableLanguages[selectedLanguageIndex + 1])
        } else {
            languageState.setLanguage(availableLanguages[0])
        }

        e.stopPropagation()
    }


    if (availableLanguages && availableLanguages.length > 1) {
        return (
            <>
                {!props.fullToggler &&
                    <LanguageTogglerRoot
                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                            toggleLanguage(e)
                        }
                        color={props.color}
                        fontSize={props.fontSize}
                        margin={props.margin}>
                        <span style={{ fontWeight: "bold" }}>
                            {selectedLanguage}
                        </span>
                    </LanguageTogglerRoot>
                }

                {props.fullToggler &&
                    <LanguageTogglerRoot
                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                            toggleLanguage(e)
                        }
                        color={props.color}
                        fontSize={props.fontSize}
                        margin={props.margin}>
                        <span style={{ fontWeight: "bold" }}>{selectedLanguage} </span> / {selectedLanguage === availableLanguages[0] ? availableLanguages[1] : availableLanguages[0]}
                    </LanguageTogglerRoot>
                }
            </>
        )
    } else {
        return (
            <>
            </>
        )
    }
}
export default LanguageToggler
