import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom";
import branding from "../../branding/branding";

const EmptyTileWrapper = styled.div <{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 180px;
  background-color: ${props => props.backgroundColor};
  color: ${branding.primaryColor ?? "black"};
  font-family: ${branding.font1};
  border-radius: 5px;
`

const EmptyTileRow = styled.div`
  /* height: 45%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-family: ${branding.font1};
  color: ${branding.receptionPage.emptyTileTextColor};
  font-style: normal;
  font-weight: 600;
  font-size: 16pt;
`

type OpenMoreBtn = {
    message: string;
    navLink: string;
};

const EmptyTileButton: React.FunctionComponent<OpenMoreBtn> = (props) => {
    return <EmptyTileButtonPanel to={props.navLink}>{props.message}</EmptyTileButtonPanel>
}

const EmptyTileButtonPanel = styled(Link)`
  display: block;
  font-family: ${branding.font1};
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 26px;
  border-radius: 40px;
  border: 1px solid;
  border-color: ${branding.primaryColor ?? "#000"};
  background-color: transparent;
  color: ${branding.primaryColor ?? "#000"};
  padding: .40rem 2rem;
  
  &:hover {
      background-color: ${branding.primaryColor ?? "#000"};
      color: #fff;
      text-decoration: none;
  }
`

interface EmptyTileProps {
    header: string
    buttonNavLink?: string
    buttonMessage?: string
    bgColor: string
    hideButton?: boolean
}

const EmptyTile: React.FunctionComponent<EmptyTileProps> = (props: EmptyTileProps) => {
    return <EmptyTileWrapper backgroundColor={props.bgColor}>
        <EmptyTileRow>{props.header}
            {props.hideButton ? null : <EmptyTileButton navLink={props.buttonNavLink ? props.buttonNavLink : ""} message={props.buttonMessage ? props.buttonMessage : ""} />}
        </EmptyTileRow>
    </EmptyTileWrapper>
}

export default EmptyTile
