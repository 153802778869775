import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Nav, Row, Col } from 'react-bootstrap'
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { getInterest } from "../../backendServices/BackendServices"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import { IconEdit, IconPhone, IconMobile, IconEmail, IconFacebook, IconTwitter, IconLinkedIn, IconXing, IconInstagram, IconYouTube, IconPinterest, IconLocation, IconLanguages } from "../../ui/Icons"
import { AvatarWithDefault } from "../../ui/AvatarWithDefault"
import { MyProfilePageMode } from "./MyProfilePageContent"
import { CategoryType, DisabledFields, getCategorySectionTitle } from "./EditMyProfileLayout"
import { Category } from "../../backendServices/Types"
import CompanyDetails from "../../ui/CompanyDetails"
import { findCountryName } from "./CountrySwitcherHelper"
import { getListOfLanguagesString } from "./LanguageSwitcherHelper"
import { SocialMediaType } from "./MyProfilePageContentBranding"

const version = document.getElementById("version")?.getAttribute("data-value")

const corussoftLogo = "/corus_RGB_L_dark.png"

const BottomMenuCompanyLogo = styled.div`
    background-color: #fff;
    position: relative;
    border-top: 1px solid ${branding.mainInfoColor};
    bottom: 70px;
    height: 60px;
    font-family: ${branding.font1};
    font-weight: 300;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`



const ScrollContainerRoot = styled.div<{ guestBannerHeight: number }>`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  padding-top: 5px;
  height: calc(100vh - 60px - 50px - ${props => props.guestBannerHeight + "px"}); /* topbar + guestBanner */
  overflow: hidden; 
`

const EditMyProfileContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 40px;
  margin-top: 20px;
`

const EditMyProfileLink = styled.div`
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  color: ${branding.mainInfoColor};
  border-radius: 10px;
  margin-right: 20px;
  &:hover {
    color: ${branding.mainInfoColor};
  }
`

const ProfileContactDetailsContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`

const ProfileContactDetailsText = styled.div`
  text-decoration: none;
  font-size: 18px;
  line-height: 20px;
  color: ${branding.mainInfoColor};
  border-radius: 10px;
  margin-left: 20px;
  &:hover {
    color: ${branding.mainInfoColor};
    }

    @media (max-width: 1600px) {
        font-size: 16px; 
        line-height: 22px;
    }

    @media (max-width: 1200px) {
        font-size: 12px; 
        line-height: 18px;    
    }
`


/*********************************************************************************************
 * site assembly
**********************************************************************************************/

interface ViewMyProfileProps {
    setMyProfileMode: (mode: MyProfilePageMode) => void
    guestBannerHeight: number
}

const ViewMyProfileLayout: React.FunctionComponent<ViewMyProfileProps> = (props: ViewMyProfileProps) => {
    const strings = useLanguageState().getStrings()
    const loggedInUser = useLoggedInState().user()!

    return (<div style={{ fontFamily: branding.font1, color: branding.mainInfoColor }}>
        <div style={{ backgroundColor: "white" }}>
            <ScrollContainerRoot guestBannerHeight={props.guestBannerHeight}>
                <EditMyProfileContainer>
                    <EditMyProfileLink onClick={() => props.setMyProfileMode(MyProfilePageMode.EDIT)}>{strings.myProfilePageContent.editProfileButtonText}</EditMyProfileLink>
                    <div style={{ cursor: "pointer", color: branding.primaryColor ?? "black" }} onClick={() => props.setMyProfileMode(MyProfilePageMode.EDIT)}>{IconEdit({ fill: branding.sideIconBar.sideIconColorDark })}</div>
                </EditMyProfileContainer>
                <ContentScrollContainer>
                    <div style={{ position: "relative" }}>
                        <Row style={{ display: "flex !important" }}>
                            <Col xs={2}>
                            </Col>
                            <Col xs={8}>
                                <ProfileDetailBody />
                                {loggedInUser && loggedInUser.organizations && loggedInUser.organizations[0] &&
                                    <CompanyDetails organization={loggedInUser.organizations[0]} />}
                            </Col>
                            <Col xs={2}>
                            </Col>
                        </Row>
                    </div>
                </ContentScrollContainer>
            </ScrollContainerRoot>
        </div>
        <div style={{ position: "absolute", width: "100%", alignItems: "center", justifyContent: "center" }}>
            <BottomMenuCompanyLogo>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img style={{ width: "80px", marginBottom: "10px" }} src={corussoftLogo} alt="Corussoft GmbH" />
                    <div style={{ fontSize: "14px" }}>Version {version}</div>
                </div>
            </BottomMenuCompanyLogo>
        </div>

    </div>
    );
}

export default ViewMyProfileLayout

// #region GLobal layout 

const Title3 = styled.h3`
margin-bottom:  10px;
font-weight: bold;
font-family: ${branding.font1};

@media (max-width: 1600px) {
    font-size: 20px; 
    line-height: 30px;    
}

@media (max-width: 1200px) {
    font-size: 16px; 
    line-height: 25px;    
}
`
const Title4 = styled.h4`
margin-bottom:  10px;
font-family: ${branding.font1};

@media (max-width: 1600px) {
    font-size: 18px; 
    line-height: 30px;   
}

@media (max-width: 1200px) {
    font-size: 16px; 
    line-height: 25px;    
}
`

const Paragraph = styled.p`
    white-space: pre-line;
    margin-top: 10px;
    font-size: 18px;
    line-height: 20px;

    @media (max-width: 1600px) {
        font-size: 16px; 
        line-height: 22px;
    }

    @media (max-width: 1200px) {
        font-size: 14px; 
        line-height: 20px;    
    }
`
//#endregion

// #region Profile Contact Details
const ProfileContactsWrapper = styled(Nav)`
    display: flex;
    flex-direction: column;
    margin: 20px 0px 20px 0px;
`

const ProfileSocialsWrapper = styled(Nav)`
    margin-top: 30px;
    margin-bottom: 40px;
`
const ProfileSocialItem = styled(Nav.Link)`
    margin-right: 20px;

    &.nav-link {
        padding: 0 !important;
    }
`

function ProfileContacts() {
    const user = useLoggedInState().user()
    const lang = useLanguageState().getLanguage()
    const hiddenFields = branding.myProfilePageContent.contactInfosHiddenFields.map(it => it.toUpperCase())

    return (
        <ProfileContactsWrapper>
            <Row>
                <Col xs={24} md={12}>
                    {user?.phone && <ProfileContactDetailsContainer>
                        {IconPhone({ fill: branding.sideIconBar.sideIconColorDark })}
                        <ProfileContactDetailsText>{user?.phone}</ProfileContactDetailsText>
                    </ProfileContactDetailsContainer>}

                    {user?.mobile && <ProfileContactDetailsContainer>
                        {IconMobile}
                        <ProfileContactDetailsText>{user?.mobile}</ProfileContactDetailsText>
                    </ProfileContactDetailsContainer>}

                    <ProfileContactDetailsContainer>
                        {IconEmail({ fill: branding.sideIconBar.sideIconColorDark })}
                        <ProfileContactDetailsText>{user?.email}</ProfileContactDetailsText>
                    </ProfileContactDetailsContainer>
                    <ProfileContactDetailsContainer style={{ alignItems: "flex-start" }}>
                        {IconLocation({ fill: branding.sideIconBar.sideIconColorDark })}
                        {user?.countrycode && <ProfileContactDetailsText>{findCountryName(user?.countrycode, lang)}</ProfileContactDetailsText>}
                        {user?.city && hiddenFields.indexOf(DisabledFields.CITY) < 0 && <ProfileContactDetailsText>{user.city}</ProfileContactDetailsText>}
                    </ProfileContactDetailsContainer>
                    <ProfileContactDetailsContainer>

                    </ProfileContactDetailsContainer>
                    {user?.languages && user?.languages.length > 0 &&
                        <ProfileContactDetailsContainer>
                            {IconLanguages({ fill: branding.sideIconBar.sideIconColorDark })}
                            <ProfileContactDetailsText>{getListOfLanguagesString(user?.languages, lang)}</ProfileContactDetailsText>
                        </ProfileContactDetailsContainer>
                    }
                </Col>
            </Row>
            <ProfileSocialsWrapper>
                {branding.myProfilePageContent.availableSocialMedias.map((item, index) => {
                    if (item === SocialMediaType.FACEBOOK) {
                        return <>
                            {user?.facebook && <ProfileSocialItem key={index} href={user?.facebook} target="_blank">
                                {IconFacebook({ fill: branding.sideIconBar.sideIconColorDark, width: "25", height: "25" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    if (item === SocialMediaType.TWITTER) {
                        return <>
                            {user?.twitter && <ProfileSocialItem key={index} href={user?.twitter} target="_blank">
                                {IconTwitter({ fill: branding.sideIconBar.sideIconColorDark, width: "25", height: "25" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    if (item === SocialMediaType.LINKEDIN) {
                        return <>
                            {user?.linkedIn && <ProfileSocialItem key={index} href={user?.linkedIn} target="_blank">
                                {IconLinkedIn({ fill: branding.sideIconBar.sideIconColorDark, width: "25", height: "25" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    if (item === SocialMediaType.XING) {
                        return <>
                            {user?.xing && <ProfileSocialItem key={index} href={user?.xing}>
                                {IconXing({ fill: branding.sideIconBar.sideIconColorDark, width: "25", height: "25" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    if (item === SocialMediaType.INSTAGRAM) {
                        return <>
                            {user?.instagram && <ProfileSocialItem key={index} href={user?.instagram}>
                                {IconInstagram({ fill: branding.sideIconBar.sideIconColorDark, width: "25", height: "25" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    if (item === SocialMediaType.YOUTUBE) {
                        return <>
                            {user?.youTube && <ProfileSocialItem key={index} href={user?.youTube}>
                                {IconYouTube({ fill: branding.sideIconBar.sideIconColorDark, width: "25", height: "25" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    if (item === SocialMediaType.PINTEREST) {
                        return <>
                            {user?.pinterest && <ProfileSocialItem key={index} href={user?.pinterest}>
                                {IconPinterest({ fill: branding.sideIconBar.sideIconColorDark, width: "25", height: "25" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    return null
                })
                }


            </ProfileSocialsWrapper>
        </ProfileContactsWrapper >
    )
}
// #endregion

// #region Profile Details
const ProfileDetailRowHeader = styled(Row)`
    font-size: 12px;
    line-height: 17px;
    margin: 0 0 10px 0;
`

const PersonImageAndLogo = styled(Col)`
    margin-top: 80px;
`

const UserImageDiv = styled.div`
    display: flex;
    align-items: center;
    width:150px;
    height:150px;

    .avatar-styled {
        width: 150px;
        height: 150px;
        line-height: 150px;
    }

    .avt-cont {
        width: 125px;
    }

    @media (max-width: 1600px) {
        width: 120px;
        height: 120px; 

        .avatar-styled {
        width: 120px;
        height: 120px;
        line-height: 120px;
        }
    }

    @media (max-width: 1400px) {
        width: 100px;
        height: 100px;  

        .avatar-styled {
        width: 100px;
        height: 100px;
        line-height: 100px;
        }
    }

    @media (max-width: 1200px) {
        width: 80px;
        height: 80px;  

        .avatar-styled {
        width: 80px;
        height: 80px;
        line-height: 80px;
        }
    }
`

const ProfileDetailNameTeaserCol = styled(Col)`
    margin-top: 80px;
`

interface CategoryData {
    interests: Category[]
    offerings: Category[]
    lookingfor: Category[]

    selectedInterests: Category[]
    selectedOfferings: Category[]
    selectedLookingfor: Category[]
}

const ProfileDetailBody: React.FunctionComponent = () => {
    const userState = useLoggedInState()
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()

    const currentUserInfo: string = userState.user()?.infotext || ""
    const currentUserName: string = [userState.user()?.firstName, userState.user()?.lastName].filter(Boolean).join(" ")
    const currentUserPosition: string = [userState.user()?.position, userState.user()?.company].filter(Boolean).join(" " + strings.communicationArea.personCompanyLink + " ")

    const [categoryData, setCategoryData] = useState<CategoryData>({ interests: [], offerings: [], lookingfor: [], selectedInterests: [], selectedOfferings: [], selectedLookingfor: [] })

    function getData() {
        getInterest().then(res => {
            if (res.content.interests) {
                const interests: Category[] = res.content.interests ?? []
                const offerings: Category[] = res.content.lookingforandoffering ?? []
                const lookingfor: Category[] = res.content.lookingforandoffering ?? []
                const selectedInterests = interests.filter(i => (userState.user()?.interests ?? []).includes(i.id))
                const selectedOfferings = offerings.filter(i => (userState.user()?.offering ?? []).includes(i.id))
                const selectedLookingfor = lookingfor.filter(i => (userState.user()?.lookingfor ?? []).includes(i.id))
                setCategoryData(
                    {
                        interests: interests,
                        offerings: offerings,
                        lookingfor: lookingfor,
                        selectedInterests: selectedInterests,
                        selectedOfferings: selectedOfferings,
                        selectedLookingfor: selectedLookingfor
                    }
                )
            }
        })
    }

    useEffect(() => {
        getData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getData()
    }, [lang]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ProfileDetailRowHeader>
            <PersonImageAndLogo xs={8} md={2}>
                <Row>
                    <UserImageDiv>
                        <AvatarWithDefault size={100} src={userState.user()?.logoUrl} alt={[userState.user()?.firstName, userState.user()?.lastName].join(" ") ?? "#"} />
                    </UserImageDiv>
                </Row>
            </PersonImageAndLogo>
            <ProfileDetailNameTeaserCol xs={16} md={9} className="offset-md-1">
                <Title3>{currentUserName}</Title3>
                <Paragraph>{[currentUserPosition, userState.user()?.industry].filter(Boolean).join(" | ")}</Paragraph>
                <ProfileContacts />
                <Paragraph>{currentUserInfo}</Paragraph>
                <>
                    {branding.myProfilePageContent.categoriesOrder.map((item, index) => {
                        switch (item) {
                            case "INTERESTS":
                                return <ContactCategoryBindings key={index} type={CategoryType.INTERESTS} data={categoryData} />

                            case "OFFERS":
                                return <ContactCategoryBindings key={index} type={CategoryType.OFFERS} data={categoryData} />

                            case "NEEDS":
                                return <ContactCategoryBindings key={index} type={CategoryType.NEEDS} data={categoryData} />

                            default: return null
                        }

                    })}
                </>
            </ProfileDetailNameTeaserCol>
        </ProfileDetailRowHeader>
    )
}
// #endregion 

// #region Interests
const InterestsTitleRow = styled(Row)`
    margin: 30px 0 0 0;
`

const InterestsViewRow = styled(Row)`
    display: flex;
    margin: 0 -5px 50px -5px;
    flex-wrap: wrap;
    justify-content: flex-start;
`

const InterestItem = styled.div`
    display: inline-block;
    padding: .5rem;
    font-size: 18px;
    border: 1px solid ${branding.mainInfoColor}; 
    font-weight: normal;
    text-transform: unset;
    margin: .3rem;
    border-radius: 5px;
`

export function getNoSelectedCategoriesMessage(strings: any, type: CategoryType) {
    switch (type) {
        case CategoryType.INTERESTS:
            return strings.noInterestsSelectedMessage

        case CategoryType.OFFERS:
            return strings.noOffersSelectedMessage

        case CategoryType.NEEDS:
            return strings.noNeedsSelectedMessage

        default:
            return strings.noInterestsSelectedMessage
    }
}

function getCategoriesLength(data: CategoryData, type: CategoryType) {
    switch (type) {
        case CategoryType.INTERESTS:
            return data.interests.length || 0

        case CategoryType.OFFERS:
            return data.offerings.length || 0

        case CategoryType.NEEDS:
            return data.lookingfor.length || 0

        default:
            return 0
    }
}

interface ContactCategoryBindingsProps {
    data: CategoryData
    type: CategoryType
}
const ContactCategoryBindings: React.FunctionComponent<ContactCategoryBindingsProps> = (props) => {
    const langState = useLanguageState()
    const strings = langState.getStrings().myProfilePageContent

    let content: JSX.Element = <div />



    if (getCategoriesLength(props.data, props.type) > 0) {
        let selectedDataSet: Category[] = [];
        switch (props.type) {
            case CategoryType.INTERESTS:
                selectedDataSet = props.data.selectedInterests
                break
            case CategoryType.OFFERS:
                selectedDataSet = props.data.selectedOfferings
                break
            case CategoryType.NEEDS:
                selectedDataSet = props.data.selectedLookingfor
                break
        }

        content = <>
            {selectedDataSet.map(category => {
                return <InterestItem>{category.name}</InterestItem>
            })}
        </>
    }
    else {
        content =
            <div style={{ margin: ".3rem" }}>
                <Paragraph>{getNoSelectedCategoriesMessage(strings, props.type)}</Paragraph>
            </div>
    }

    return <>
        <InterestsTitleRow>
            <Title4>{getCategorySectionTitle(strings, props.type)}</Title4>
        </InterestsTitleRow>
        <InterestsViewRow>
            {content}
        </InterestsViewRow>
    </>
}


// #endregion

// #region Action Toolbar

